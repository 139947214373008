<template>
  <!--  Nav-->
  <div class="footer-align">
    <nav class="navbar navbar-expand-md navbar-light bg-white sticky-top">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img class="header-logo" src="@/assets/logo.svg" alt="logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                aria-current="page"
                href="https://www.redcastletechnology.com/"
                >Home</a
              >
            </li>
            <li class="nav-item active">
              <a
                class="btn btn-primary-dark py-2 ml-3 font-weight-bold"
                href="#"
                @click="register"
                >Create Account</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!--  Content-->
    <div class="d-flex align-items-center my-6">
      <div class="custom-container">
        <div class="row">
          <div class="col col-lg-6 d-none d-lg-flex text-right">
            <img
              class="align-middle secure-login"
              src="@/assets/secure-login.svg"
              alt="people holding shield"
            />
          </div>
          <div class="col col-md-9 col-lg-6 mx-auto">
            <div class="card rounded shadow-sm">
              <div class="card-body">
                <div class="pb-3 mb-4 border-bottom">
                  <div class="h4 mb-1">Welcome Back!</div>
                  <span class="text-muted">Login to your account </span>
                </div>

                <Form @submit="login" v-slot="meta" :validation-schema="schema">
                  <div class="form-group">
                    <label class="font-weight-bold">Work Email</label>
                    <input-field icon="user">
                      <template v-slot:input>
                        <Field
                          type="text"
                          name="workEmail"
                          placeholder="Work email"
                          :class="meta.errors.workEmail ? 'is-invalid' : ''"
                        />
                        <ErrorMessage
                          name="workEmail"
                          class="invalid-feedback"
                        />
                      </template>
                    </input-field>
                  </div>

                  <div class="form-group">
                    <label class="font-weight-bold">Password</label>
                    <input-field icon="key">
                      <template v-slot:input>
                        <Field
                          :type="`${showPassword ? 'text' : 'password'}`"
                          name="password"
                          placeholder="Password"
                          :class="meta.errors.password ? 'is-invalid' : ''"
                        />
                        <ErrorMessage
                          name="password"
                          class="invalid-feedback"
                        />
                      </template>
                    </input-field>

                    <div
                      class="d-flex align-items-center mt-2 justify-content-between ml-0"
                    >
                      <div class="form-check d-flex align-items-center px-1">
                        <input
                          class="cus-checkbox m-0 mr-2"
                          type="checkbox"
                          value="true"
                          v-model="showPassword"
                          id="defaultCheck1"
                        />
                        <label
                          class="my-0 cursor-pointer text-muted"
                          for="defaultCheck1"
                        >
                          Show Password
                        </label>
                      </div>

                      <div class="">
                        <router-link
                          :to="{ name: 'searchEmail' }"
                          class="btn btn-link my-0"
                          >Forgot password?</router-link
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row my-3 justify-content-center">
                    <div class="col-sm-12 col-md-8">
                      <button
                        class="btn-primary btn-lg-cus"
                        :disabled="loading"
                      >
                        <span class="font-weight-bold">Login </span>
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                      </button>
                    </div>
                    <div class="col-12 mt-2">
                      <div class="text-center text-muted">
                        Don't have an account?
                        <a href="#" class="font-weight-bold" @click="register">
                          Create one</a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col">
                      <div
                        v-if="message"
                        class="alert alert-danger"
                        role="alert"
                      >
                        {{ message }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col"></div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer-component></footer-component>
</template>

<script src="./login.ts"></script>

<style scoped></style>
