import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AuthStore from "@/store/auth-store";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { ILogin } from "@/types/Auth";
import { IEmployee } from "@/types/Employee";
import { IOrganisation } from "@/types/Organisation";
import store from "@/store";
import FooterComponent from "@/components/FooterComponent.vue";

@Options({
  name: "Login",
  data: function () {
    return {
      loading: false,
      message: "",
      showPassword: false,
      schema: yup.object().shape({
        workEmail: yup
          .string()
          .required("Email is required!"),
        password: yup.string().required("Password is required!"),
      }),
    };
  },
  computed: {
    loggedIn() {
      return this.authStore.isSignedIn();
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/admin/home");
    }
  },
  components: {
    FooterComponent,
    Form,
    Field,
    ErrorMessage,
  },
})
export default class Login extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  protected authStore = AuthStore;
  message = "";
  loading = false;

  async login(user: ILogin) {
    this.loading = true;

    this.message = "";
    this.workForceService
      .postOrPut<void>("/account/login", user as ILogin, undefined)
      .then((response: any) => {
        if (!response.isError) {
          store.commit("reset");
          store.commit("login");
          this.authStore.setToken(response.content);
          this.getEmployee();
        } else if (response.isSuccess == false) {
          this.message = response.errors;
          this.loading = false;
        } else {
          this.loading = false;
          const errors = response.errors.errors;
          for (const key in errors) {
            this.message += key + ": " + errors[key][0] + "<br>";
          }
        }
      });
  }

  async getEmployee(): Promise<void> {
    this.workForceService
      .get<IEmployee>(
        `/employee/${this.authStore.getTokenData().EmployeeId}`,
        false
      )
      .then((response) => {
        if (!response.isError) {
          store.commit("authUser", response.content);
          this.getReleaseVersion();
        }
      });
  }

  async getReleaseVersion(): Promise<void> {
    await this.workForceService
      .get<string>(`/home/releaseVersion`, false)
      .then((response) => {
        store.commit("version", response.content);
        this.getOrgData();
      });
  }

  async getOrgData(): Promise<void> {
    await this.workForceService
      .get<IOrganisation>(
        `/organisation/${this.authStore.getTokenData().OrganisationId}`,
        false
      )
      .then((response) => {
        store.commit("orgDetails", response.content);
        this.$router.push("/admin/home");
        this.loading = false;
      });
  }

  register(e: Event): void {
    e.preventDefault();
    this.$router.push("/account/register");
  }
}
